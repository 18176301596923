import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"

class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: props.expanded ? false : true
    }
  }
  render() {
    return (
      <li className={this.state.collapsed ? "timeline-item" : "timeline-item expanded"}
      onClick={() => this.setState(state => ({ collapsed: !state.collapsed }))}  >
          <div className="timeline-info" >
              <span>{this.props.info}</span>
          </div>
          <div className="timeline-marker"/>
          <div className="timeline-content">
              { this.props.title && <h3 className="timeline-title">{this.props.title}</h3>}
              { this.props.subtitle && <h4 className="timeline-title">{this.props.subtitle}</h4>}
              <p
                className={this.state.collapsed ? null : "expanded"}
                dangerouslySetInnerHTML={{__html: this.props.text}}
              />
              { this.props.text2 && (
                <p
                  className={this.state.collapsed ? null : "expanded"}
                  dangerouslySetInnerHTML={{__html: this.props.text2}}
                />
              )}
              { this.props.text3 && (
                <p
                  className={this.state.collapsed ? null : "expanded"}
                  dangerouslySetInnerHTML={{__html: this.props.text3}}
                />
              )}
              { this.props.text4 && (
                <p
                  className={this.state.collapsed ? null : "expanded"}
                  dangerouslySetInnerHTML={{__html: this.props.text4}}
                />
              )}
              { this.props.links && (
                <p
                  className={this.state.collapsed ? null : "expanded"}
                  dangerouslySetInnerHTML={{__html: this.props.links}}
                />
              )}
              { this.props.stack && (
                <p
                  className={this.state.collapsed ? null : "expanded"}
                  dangerouslySetInnerHTML={{__html: this.props.stack}}
                />
              )}
          </div>
      </li>
    )}
}

class ItemSection extends React.Component {
  render() {
    return (
      <li className="timeline-item period">
          <div className="timeline-info"/>
          <div className="timeline-marker"/>
          <div className="timeline-content">
              <h2 className="timeline-title">{this.props.title}</h2>
          </div>
      </li>
    )}
}

const Resume = ({data}) =>
  <Layout>
    <Helmet htmlAttributes={{ class:'pro resume' }} />
    <SEO title="Resume"
      description={data.site.siteMetadata.descriptions.resume} />
    <>
      <h1>Resume</h1>
      <div style={{ marginTop: '3rem', }}>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                      <ul className="timeline timeline-centered">

                          <Item expanded={true} 
                                info="In a few words"
                                text="I'm a senior developer with about 10 years of professional experience, mainly in Android development, but I've enjoyed programming for a long time now."
                                text2="I consider myself as a curious & pragmatic developer, enjoying mobile but also web dev, with a sensibility in user experience. Continuous integration & deployments are my things too."
                                text3="For Android, I've relied on <a target='_blank' href='https://kotlinlang.org/'>Kotlin</a> since 2015, but I'm also familiar with <a target='_blank' href='https://reactnative.dev/' >React Native</a> which I use in production on some side projects."
                                text4="On the web, I'm a fan of the JamStack, working with both <a target='_blank' href='https://nextjs.org/'>Next.JS</a> & <a target='_blank' href='https://gatsbyjs.org/'>GastbyJS</a>."
                                />

                          <ItemSection title="Jobs" />
                          <Item info="Since August 2021"
                                title="Freelance - Indie"
                                subtitle="Android / iOS / React Native"
                                />

                          <Item info="June 2014 - July 2021"
                                title="Prisma Media"
                                subtitle="Lead Android Dev"
                                text="Prisma Media is the French leader of both print & digital press."
                                text2="Here, I'm in charge of the development of various apps & working on improving the organization of our Android team which is fully remote around France."
                                text3="The main focuses are about quality, reliability & industrialization of our apps with a pragmatic approach to deliver the best to our users."
                                links="<span class='title' >Some apps on which I work: </span>
                                <ul>
                                <li><a target='_blank' href='https://play.google.com/store/apps/details?id=fr.playsoft.teleloisirs' >Télé-Loisirs</a>: France's leading media app with +5M DAU</li>
                                <li><a target='_blank' href='https://play.google.com/store/apps/details?id=com.prismamedia.elisa' >eLisa</a>: our dematerialized platform (in progress)</li>
                                <li><a target='_blank' href='https://play.google.com/store/apps/details?id=com.prismamedia.uselessneon' >NEON</a>: 5 useless things to discover every day</li>
                                <li><a target='_blank' href='https://play.google.com/store/apps/dev?id=5611139809923286355'>all apps</a></li>
                                </ul>"
                                stack="<span class='title' >Tech stack:</span> mainly Kotlin, using some of the greatest libraries like Retrofit/OkHttp, Glide, Coil, Koin (DI fan !)"
                                />

                          <Item info="Since May 2011"
                                title="Météo Villes"
                                subtitle="Freelance Dev (Android / iOS / React Native)"
                                text="Working with a well known meteorologist on his applications, initially on Android only, then also on iOS."
                                links="<span class='title' >Links:</span> <a target='_blank' href='https://www.meteo-villes.com/' target='_blank'>Website</a> / <a target='_blank' href='https://play.google.com/store/apps/details?id=com.meteo.android' target='_blank'>Paris Android</a> / <a target='_blank' href='https://apps.apple.com/app/m%C3%A9t%C3%A9o-paris/id346423596' target='_blank'>Paris iOS</a>"
                                stack="<span class='title' >Tech stack:</span> mainly Java & Kotlin for Android, React Native for the iOS app"
                                />

                          <Item info="February 2009 - April 2014"
                                title="Cardiweb"
                                subtitle="Web & mobile developer"
                                text="Working as a contractor for various clients, such as: "
                                links="
                                <ul>
                                <li>high trafic websites like <a href='http://www.francebillet.com'>France Billet</a> & <a href='http://www.fnacspectacles.com'>Fnac Spectacles</a>, leading live shows tickets sellers</li>
                                <li>large mobile apps (shared codebase with ~50 target malls apps) for the retail industry with <a href='https://www.urw.com/fr-FR'>Unibail-Rodamco-Westfield</a></li>
                                </ul>"
                                />

                          <ItemSection title="Side Projects" />
                          <Item info="201x"
                                title="EndOfLine"
                                text="This website is a way for me to try various things, it's always under development"
                                stack="<span class='title' >Tech stack:</span> JamStack ! Trying out things with GatsbyJS & NetlifyCMS, hosted on Netlify"
                                />

                          <Item info="Since 2016"
                                title="Chain App"
                                text="Stay updated about the latest mountain bike news!"
                                links="<span class='title' >Links:</span> <a href='https://chainapp.news/' target='_blank'>Website</a> / <a href='https://play.google.com/store/apps/details?id=fr.endofline.chainapp' target='_blank'>Android</a> / <a href='https://apps.apple.com/app/chain-mountain-bike-news/id1067637873' target='_blank'>iOS</a>"
                                stack="<span class='title' >Tech stack:</span> Kotlin & Swift for the apps, NextJS for the front, React-Admin to edit the contents & Firebase Functions & MongoDB"
                                />

                          <Item info="2015"
                                title="Colorly"
                                text="A basic game about colors"
                                text2="<a href='http://colorly-app.com/' target='_blank'>Website</a>"
                                />

                          <ItemSection title="Studies" />
                          <Item info="2009"
                                title="MSc Networking, Services & Mobility"
                                subtitle="Henri Poincaré University - Nancy (FR)"
                                text="Masters degree with a large background in software development & major in network systems to keep your mind & sight open."
                                />

                          <Item info="2008"
                                title="Bachelor in Computer Sciences"
                                subtitle="Henri Poincaré University - Nancy (FR)"
                                text="Major in software development"
                                />

                          <ItemSection title="Interests" />
                          <Item info="Mountain Bike"
                                text="Riding since I was a little kid - currently in enduro" />
                          <Item info="Photography"
                                text="My first trip abroad inspired my passion for beautiful landscapes!" />
                          <Item info="Motorbike"
                                text="For the feeling of freedom you have while riding on two wheels" />
                          <Item info="Travels"
                                text="Discovering incredible sceneries & meeting foreigners" />
                          <Item info="Music"
                                text="Played the guitar for a while & still vibrating when hearing strings vibrating" />
                      </ul>
                  </div>
              </div>
          </div>
      </div>
    </>
  </Layout>
export default Resume;

export const query = graphql`
  query ResumeQuery {
    site {
      siteMetadata {
        title
        descriptions {
          resume
        }
      }
    }
  }
`
